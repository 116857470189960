import React, { useState, useEffect, useContext, useCallback } from 'react'
import Router from 'next/router'
import Layout from '../../components/Layout'
import AuthGlobal from '../../context/store/global'
import { accessUser } from '../../context/actions'

const Login = props => {
    const context = useContext(AuthGlobal)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [showChild, setShowChild] = useState(false)

    useEffect(() => {
        if (context.globalState.isAuthenticated) {
            Router.push(`/`)
        }
        setShowChild(true)
    }, [context.globalState.isAuthenticated])

    const handleSubmit = e => {
        const user = {
            email,
            password
        }

        if (email === '' || password === '') {
            toggleFormError('Enter data correctly')
        } else {
            accessUser(user, context.dispatch, toggleFormError)
        }

        e.preventDefault()
    }

    const toggleFormError = useCallback(error => {
        setError(error)
        setTimeout(() => setError(null), 3000)
    }, [])
    return (
        <Layout title='Login'>
            <section className='section'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-5 offset-xl-3'>
                            <div className='login-brand'>
                                <a href='/'>
                                    <img src='../images/newLogo.png' alt='logo' width='100%' />
                                </a>
                            </div>
                        </div>
                        <div className='col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-5 offset-xl-3'>
                            <div className='card card-primary'>
                                <div className='card-header'>
                                    <h4>Log in to your account</h4>
                                </div>
                                <div className='card-body'>
                                    {error ? <div className='alert alert-danger'> {error} </div> : null}
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label htmlFor='email'>Email</label>
                                            <input
                                                id='email'
                                                type='email'
                                                className='form-control'
                                                name='email'
                                                required
                                                autoFocus
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                            />
                                            <div className='invalid-feedback'>Please fill in your email</div>
                                        </div>

                                        <div className='form-group'>
                                            <div className='d-block'>
                                                <label htmlFor='password' className='control-label'>
                                                    Password
                                                </label>
                                            </div>
                                            <input
                                                id='password'
                                                type='password'
                                                className='form-control'
                                                name='password'
                                                required
                                                onChange={e => setPassword(e.target.value)}
                                                value={password}
                                            />
                                            <div className='invalid-feedback'>Please fill in your password</div>
                                        </div>

                                        <div className='form-group'>
                                            <button type='submit' className='btn btn-primary btn-lg btn-block'>
                                                Login
                                            </button>
                                        </div>
                                        <div className='form-group'>
                                            <div className='float-right'>
                                                <a href='/forgotpassword' className='text-small'>
                                                    Have you forgotten your password?
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Login
